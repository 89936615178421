@font-face {
    font-family: 'M PLUS 1p';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mplus1p/v28/e3tmeuShHdiFyPFzBRrQDBcQfEnXgPRE4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/vt323/v17/pxiKyp0ihIEF2isfFJXUdVNF.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'MaehongsonMedium';
    src: url('./font/PK_Maehongson/PK_Maehongson_Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Jibjibfont';
    src: url('./font/Jibjib/Jibjibfont-Regular.ttf') format('truetype');
}

.App {
    text-align: center;
    padding: 6px 12px;
}

.displayImg {
    padding: 16px;
}

.displayCard {
    padding: 16px;
}

.displayInput {
    padding: 16px;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.inputStyles {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 6px 12px;
    height: 38px;
    width: 120px;
}

.allCoopong {
    display: flex;

}

.textBoxStyles2 {

    padding-top: 16px;
    padding-left: 650px;
    width: 100%;
    height: 84px;

}


.cardCoopong {
    padding: 4px;
}

.txtNum1 {
    font-size: 70px;
    opacity: 0.75;
    font-family: "Jibjibfont";
    font-weight: 500;
    font-style: normal;
    margin-bottom: 8px;
}

.txtNum2 {
    color: #393C39;
    font-size: 18px;
    opacity: 0.75;
    margin-left: 1px;
    margin-top: -22px;
    font-family: "Jibjibfont";
    font-weight: 500;
    font-style: normal;
}

/*.txtNum1 {*/
/*    color: #393C39;*/
/*    font-size: 73px;*/
/*    opacity: 0.8;*/
/*    font-family: "MaehongsonMedium";*/
/*    font-weight: 500;*/
/*    font-style: normal;*/
/*}*/

/*.txtNum2 {*/
/*    color: #393C39;*/
/*    font-size: 25px;*/
/*    opacity: 0.8;*/
/*    margin-top: -30px;*/
/*    font-family: "MaehongsonMedium";*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/

.txtNumCoopon {
    padding-right: 34px;
    margin-left: 2px;
}


.textNumberloop {
    display: flex;
    margin-top: 12px;
}

.txtNum3 {
    color: #393C39;
    font-size: 10px;
    margin-top: -15px;
    padding-left: 640px;
}

.dateTitle {
    padding-top: 45px;
    padding-left: 640px;
    display: flex;
    flex-direction: column;
}

.dateTitleThai {

    color: #393C39;
    font-size: 55px;
}

.dateTitleEng {

    color: red;
    font-size: 40px;
    margin-top: -20px;
}

.noCooponTitle {
    padding-left: 970px;
    padding-top: 100px;
    color: #393C39;
    font-size: 55px;
}



