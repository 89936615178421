.textRight{
    text-align: right;
}

.textLeft{
    text-align: left;
}

.textCenter{
    padding-top:20px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

