.cardImage{
    padding: 16px;
    height: 360px;
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
}

.cardContent{
    display: flex;
    flex-direction: column;
}

.textBoxStyles{

    border-radius: 8px;
    padding: 6px 12px;

}



.textBoxNumberCoopon{
    width: 260px;

}

.contentNumberCoopon{

    top: 10px;
    position: relative;
    left: 333px;

}

.contentDateTHCoopon{
    font-size: 28px;
    top: 15px;
    position: relative;
    left: 100px;
    color: #393C39;
}



.contentDateENCoopon{
    font-size: 18px;
    top: 5px;
    position: relative;
    left: 335px;
    color: red;
}

.textBoxDateENCoopon{
    height: 33px;
    width: 230px;
    font-size: 16px;
}

.contentNoCoopon{
    top: 55px;
    position: relative;
    left: 510px;
}

.textBoxNoCoopon{
    height: 48px;
    width: 75px;
    font-size: 25px;
}


/*.disPlaytxtNum1{*/
/*    color: #393C39;*/
/*    font-size: 30px;*/
/*    font-family: "MaehongsonMedium";*/
/*    font-weight: 500;*/
/*    font-style: normal;*/
/*}*/

/*.disPlaytxtNum2{*/
/*    color: #393C39;*/
/*    font-size: 14px;*/
/*    margin-top: -10px;*/
/*    font-family: "MaehongsonMedium";*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/
.disPlaytxtNum1{
    color: #393C39;
    font-size: 30px;
    font-family: "Jibjibfont";
    font-weight: 500;
    font-style: normal;
}
.disPlaytxtNum2{
    color: #393C39;
    font-size: 14px;
    margin-top: -10px;
    font-family:"Jibjibfont";
    font-weight: 400;
    font-style: normal;
}





.disPlaytxtNumCoopon{
   padding-right: 16px;
}

.disPlaytextNumberloop{
    display: flex;
  }


